import React from 'react'
import Header from "../components/header"
import Seo from "../components/seo"
import RegisterNewsLetterBody from '../components/registerNewsLetter'

const backendUrl = "https://us-central1-nov182021.cloudfunctions.net/server/news-letter"

const RegisterNewsLetter = () => {
    return (
        <>
            <Header/>
            <Seo title="メルマガ登録ページ" 
                 description="メルマガ登録ページです。"
            />
            <h3 style={{marginTop: "150px", textAlign: "center"}}>無料メルマガ登録フォーム</h3>
            <div style={{width: "80%", margin: "0 auto 80px"}}>
                <RegisterNewsLetterBody 
                    fromWhere={typeof window !== "undefined" && window.location.search}
                    backendUrl={backendUrl}
                    text={<p>React、Next.js、TypeScriptなど最新のウェブ開発のお役立ち情報を、ビギナー向けにかみ砕いて無料配信中。<br/>（*配信はいつでも停止できます）</p>}
                />
            </div>

        </>
    )
}

export default RegisterNewsLetter